.payment {
  .card {
    width: 100%;
    border: none;
  }

  .btr {
    border-top-right-radius: 5px !important;
  }

  .btl {
    border-top-left-radius: 5px !important;
  }

  .btn-dark {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
    width: 100px;
  }
  
  .little-spinner {
    height: 16px;
    width: 16px;
    margin-top: 4px;
    border-width: 2px;
  }

  .btn-dark:hover {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
  }

  .nav-pills {
    display:table !important;
    width:100%;
  }

  .nav-pills .nav-link {
    border-radius: 0px;
    border-bottom: 1px solid #0d6efd40;
  }

  .nav-item {
    display: table-cell;
    background: #0d6efd2e;
  }

  .form {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 10px;
  }

  .input-holder {
    box-shadow: rgba(0, 0, 0, 0.03) 0px 1px 1px 0px, rgba(0, 0, 0, 0.02) 0px 3px 6px 0px;
    border-radius: 5px;
  }

  .form-label {
    margin-bottom: 0.25rem;
    font-size: 0.93rem;
    font-weight: 300;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    color: rgb(48, 49, 61);
  }

  .cupon-holder {
    padding-left: 20px;
    padding-right: 20px;
  }

  .btn-outline {
    border-color: #dee2e6;
  }

  .form-cupon {
    padding: 0.70rem;
    border-radius: 5px;
    font-size: 16px;
    line-height: 18.4px;
    font-weight: 300;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }
}