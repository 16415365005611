code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.react-flow__node-customNode {
  font-size: 12px;
  background: #eee;
  border: 1px solid #555;
  border-radius: 5px;
  text-align: center;
}

.xxxnewbutton {
  background-color: #fff;
  color: rgb(108, 117, 125);
  border-color: #dee2e6;
}
.xxxnewbutton:hover {
  background-color: #fff;
  color: rgb(108, 117, 125);
  border-color: #dee2e6;
}
.xxxnewbutton.btn:active {
  background-color: #fff;
  color: rgb(108, 117, 125);
  border-color: #dee2e6;
}
.xxxnewbutton.btn {
  background-color: #fff;
  color: rgb(108, 117, 125);
  border-color: #dee2e6;
}

.xxxdropdown > button {
  background-color: #fff;
  color: rgb(108, 117, 125);
  border-color: #dee2e6;
}
.xxxdropdown > button:hover {
  background-color: #fff;
  color: rgb(108, 117, 125);
  border-color: #dee2e6;
}
.xxxdropdown > button.btn:active {
  background-color: #fff;
  color: rgb(108, 117, 125);
  border-color: #dee2e6;
}
.xxxdropdown > button.btn {
  background-color: #fff;
  color: rgb(108, 117, 125);
  border-color: #dee2e6;
}

.customNodeStyle {
  color: #212529;
  background-color: #fff;
  border-radius: 5px;
  outline: 0;
  border: 1px solid #dee2e6;
}

.customSelectedNodeStyle {
  color: #212529;
  background-color: #fff;
  border-radius: 5px;
  outline: 0;
  border: 1px solid #86b7fe;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.customConnectorNodeStyle {
  background: #fff;
  height: 10px;
  width: 10px;
  border: 1px solid #dee2e6;
}

.customNodeLabel {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  color: rgb(108, 117, 125);
  padding: 10px 15px;
}

.customNodeBorder {
  // border-bottom: 1px solid #dee2e6;
  width: 100%;
  height: 1px;
  margin-top: 1px;
  margin-bottom: 1px;
  background-color: #dee2e6;
}

.customNodeInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: rgb(108, 117, 125);
  padding: 10px 15px;
}

.customNodeInfoCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: rgb(108, 117, 125);
  padding: 10px 15px;
}

.editorname {
  color: rgb(108, 117, 125);
}

.inputlabel {
  color: rgb(108, 117, 125);
}

#home {
  background: rgb(248,249,250);
  background-image: radial-gradient(rgba(108, 117, 125, 0.2) 1px, transparent 0);
  background-size: 12px 12px;
  background-position: -19px -19px;
}