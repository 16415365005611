@import "~bootstrap/scss/bootstrap";

body, html, #root {
  height: 100%;
}

.height-full-view {
  height: 100vh;
}

.editorname {
  // background-color: #dee2e6;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 3px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
}

.card {
  border: 1px solid #dee2e6;
}

.bg-light-gradient {
  background: rgb(248,249,250);
  background: linear-gradient(90deg, rgba(248,249,250,1) 0%, rgba(255,255,255,1) 100%); 
}